<template>
  <transition
    enter-active-class="animate__animated animate__fadeInLeft animate__fast"
    leave-active-class="animate__animated animate__fadeOutLeft animate__fast"
  >
    <div
      v-if="$route.name == 'Index' || true"
      class="login absolute z-10 self-start flex items-center justify-center flex-col"
    >
      <div class="is-relative p-20">
        <div
          class="is-size-5-mobile is-size-3-desktop text has-text-gold leading-tight mb-4"
        >
          <!-- Lasting Experiences with  -->
          Festive Lighting Solutions & Themed Decorations
        </div>
        <div
          class="is-size-7-mobile is-size-5-desktop has-text-white leading-relaxed"
        >
          <!-- MK Themed Attractions Philippines prides itself in creating
          custom-made light structures and fiberglass decorations for leisure
          centers and shopping malls worldwide.  -->
          <!-- Together we can create magical
          experiences through ingenuity and world class craftsmanship. -->
          Creating magical experiences through design ingenuity and world-class
          craftsmanship to leisure centers and shopping malls worldwide.
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$mainBg: rgba(173, 152, 97, 0.6);
$altBg: rgba(0, 0, 0, 0.6);
.login {
  background: $altBg;
  // backdrop-filter: blur(2px);
  width: 32.5%;
  min-height: 60vh;
  height: 60vh;
  left: 5%;
}

.text {
  font-family: "Nanum Myeongjo", serif;
}

.custom-title {
  position: absolute;
  top: -75%;
  left: -25%;
  width: 75%;
  padding: 1.5rem;
  background: $mainBg;
}
</style>
