import router from "../router";
const References = {
  state: () => ({
    data: {},
    increments: 20,
    skip: 0,
    take: 20,
    search: ""
  }),
  mutations: {
    setReferencesData(state, data) {
      state.data = data;
    },
    incrementReferencesSkip(state, data) {
      state.skip += data;
    },
    incrementReferencesTake(state) {
      state.take += state.increments;
    },
    setReferencesSearch(state, data) {
      state.search = data;
    }
  },
  getters: {},
  actions: {
    getReferences(context) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "admin/references",
          method: "get",
          params: {
            skip: context.state.skip || undefined,
            take: context.state.take || undefined,
            search: context.state.search || undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setReferencesData", res.data);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getReferencesForm(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `admin/references/${data.id ? data.id + "/edit" : `create`}${
            data.fr ? `?id=${data.fr}` : ""
          }`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveReferencesForm(context, data) {
      context.commit("toggleProcessing", true);
      axios({
        url: `admin/references${data.id ? "/" + data.id : ""}`,
        method: data.id ? "patch" : "post",
        data: data,
        headers: context.getters.authorizationHeader
      })
        .then(res => {
          router.push({ name: "References" }, () => {
            context.dispatch("displayMessage", {
              type: "is-success",
              message: res.data.message
            });
          });
        })
        .catch(err => {
          context.dispatch("displayMessage", {
            type: "is-danger",
            message: err.response.data.message
          });
          context.commit("setErrorsData", err.response);
          context.commit("toggleProcessing", false);
        });
    },
    deleteReference(context, data) {
      axios({
        url: `admin/references`,
        method: "delete",
        data: data,
        headers: context.getters.authorizationHeader
      });
    }
  }
};

export default References;
