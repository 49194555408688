<template>
  <div
    class="flex min-h-screen max-h-screen items-center justify-center flex-col clip"
  >
    <Header />
    <router-link :to="{ name: 'Index' }" class="w-full block">
      <video
        autoplay="autoplay"
        muted
        @ended="nextVideo"
        ref="video"
        :key="index"
      >
        <!-- <source v-for="(video, i) in data" :src="url + video" :key="i" type="video/mp4"> -->
        <source :src="url + data[10]" type="video/mp4" />
      </video>
    </router-link>
    <Login />
    <About />
    <Contact />
    <div
      class="more fixed bottom-0 h-16 flex items-center justify-between px-20 w-full has-text-light is-size-7 z-20"
    >
      <span class=""
        >&copy; MK Themed Attractions Philippines Inc. All Rights
        Reserved.</span
      >
      <span class="is-uppercase">V{{ version }}</span>
    </div>
  </div>
</template>

<script>
import Header from "@/components/public/Header";
import Login from "@/components/public/Login";
import About from "@/components/public/About";
import Contact from "@/components/public/Contact";
export default {
  title: () => {
    return `${process.env.VUE_APP_TITLE} | Portal`;
  },
  components: {
    Header,
    Login,
    About,
    Contact
  },
  data: () => ({
    index: 0,
    url: "https://mkthemedattractions.com.ph/videos",
    data: [
      "/MKTA%20PH%20-%20Intro%201.mp4",
      "/MKTA%20PH%20-%20Intro%202.mp4",
      "/MKTA%20PH%20-%20Intro%203.mp4",
      "/MKTA%20PH%20-%20Intro%204.mp4",
      "/MKTA%20PH%20-%20Intro%205.mp4",
      "/MKTA%20PH%20-%20Intro%206.mp4",
      "/MKTA%20PH%20-%20Intro%207.mp4",
      "/MKTA%20PH%20-%20Intro%208.mp4",
      "/MKTA%20Showroom%20DK%20720p.mp4",
      "/MKTA%20Showroom%20DK%20540p.mp4",
      "/MKTA%20Showroom%20DK%20540p%20LQ.mp4"
    ]
  }),
  computed: {
    version() {
      return process.env.PACKAGE_VERSION || "0.1";
    }
  },
  methods: {
    nextVideo() {
      this.$refs["video"].play();
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
video {
  min-width: 105%;
  min-height: 100%;
  pointer-events: none;
  margin: 0 !important;
  padding: 0 !important;
  vertical-align: 0;
}

.clip {
  overflow: hidden;
}
</style>
