const Helpers = {
  actions: {
    slugify(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `admin/uniquify/${data.slug}/${data.table}/${data.column}`,
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    qr(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "get",
          url: `admin/qrcode/${data}`,
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res.data);
          })
          .catch(() => {
            reject();
          });
      });
    }
  }
};

export default Helpers;
