const Errors = {
  state: {
    errors: null,
    message: "Something went wrong. Try again."
  },
  getters: {
    hasErrors: state => data => {
      if (!_.isNull(state.errors)) {
        if (_.has(state.errors, "data")) {
          let message = _.first(state.errors.data.errors[data]);
          return { type: message ? "is-danger" : "", message: message };
        }
      }
      return { type: "", message: "" };
    },
    generalErrorMessage(state) {
      return state.message;
    }
  },
  mutations: {
    setErrorsData(state, data) {
      state.errors = data;
    }
  },
  actions: {}
};

export default Errors;
