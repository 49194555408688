// import Vue from "vue";
// import Vuex from "vuex";
import Global from "./global";
import Auth from "./auth";
import Products from "./products";
import Categories from "./categories";
import Permissions from "./permissions";
import Roles from "./roles";
import Users from "./users";
import Helpers from "./helpers";
import Errors from "./errors";
import Files from "./files";
import Orders from "./orders";
import Joborders from "./joborders";
import Items from "./items";
import Dashboard from "./dashboard";
import Client from "./client";
import References from "./references";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    global: Global,
    auth: Auth,
    products: Products,
    categories: Categories,
    permissions: Permissions,
    roles: Roles,
    users: Users,
    helpers: Helpers,
    errors: Errors,
    files: Files,
    orders: Orders,
    joborders: Joborders,
    items: Items,
    dashboard: Dashboard,
    client: Client,
    references: References
  }
});
