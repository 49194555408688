import FileBrowserComponent from "./FileBrowserComponent.vue";
import { Events } from "@/events.js";

const FileBrowser = {
  install(Vue) {
    const Loader = Vue.extend(FileBrowserComponent);
    new Loader().$mount(
      document.body.appendChild(document.createElement("div"))
    );

    Vue.prototype.$fb = {
      show(params) {
        Events.$emit("show", params);
      }
    };
  }
};

export default FileBrowser;
