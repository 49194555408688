<template>
  <transition
    enter-active-class="animate__animated animate__fadeInRight animate__fast"
    leave-active-class="animate__animated animate__fadeOutRight animate__fast"
  >
    <div
      v-if="$route.name == 'Login'"
      class="login absolute z-10 self-end flex min-h-screen items-center justify-center"
    >
      <div class="p-20 w-full" @keyup.enter="login">
        <div class="-mx-2 flex flex-wrap justify-center">
          <div class="px-2 w-1/2">
            <b-field label="" class="">
              <b-input
                placeholder="E-mail"
                size="is-medium"
                v-model="email"
                icon="at"
              ></b-input>
            </b-field>
            <b-field label="" class="">
              <b-input
                placeholder="Password"
                id="password"
                size="is-medium"
                v-model="password"
                type="password"
                icon="lock-outline"
                :icon-right="reveal ? 'eye-off-outline' : 'eye-outline'"
                icon-right-clickable
                @icon-right-click="revealPassword"
              ></b-input>
            </b-field>
            <div class="buttons">
              <b-button
                size="is-medium"
                type="is-gold"
                expanded
                :loading="$store.getters.isLoading"
                @click="login"
                >Login</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    email: "",
    password: "",
    reveal: false
  }),
  methods: {
    login() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password
      });
    },
    revealPassword() {
      this.reveal = !this.reveal;
      let input = document.getElementById("password");
      input.type = this.reveal ? "text" : "password";
    }
  },
  mounted() {
    if (this.$store.getters.hasUserToken) {
      axios({
        url: "auth/logout",
        method: "post",
        headers: this.$store.getters.authorizationHeader
      });
      this.$store.commit("setUserToken", null);
      this.$store.commit("setUser", null);
    }
  }
};
</script>

<style lang="scss" scoped>
$mainBg: rgba(173, 152, 97, 0.6);
$altBg: rgba(0, 0, 0, 0.6);
.login {
  background: $altBg;
  //   background: rgba(255, 255, 255, 0.4);
  //   backdrop-filter: blur(2px);
  width: 55%;
  min-height: 60vh;
  height: 60vh;
  right: 5%;
}
.slide-enter-active,
.slide-leave-active {
  transform: translate(0, 25%);
  transition: opacity 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translate(0, 50%);
  opacity: 0;
}
</style>
