// import router from '../router';
const Client = {
  state: () => ({
    data: [],
    breadcrumbs: [
      {
        name: "Home",
        label: "Home",
        index: 0
      }
    ],
    showMenu: false,
    page: 1,
    search: "",
    column: "created_at",
    order: "desc"
  }),
  mutations: {
    setHomeData(state, data) {
      state.data = data;
    },
    setClientOrdersPage(state, data) {
      state.page = data;
    },
    setClientOrdersSearch(state, data) {
      state.search = data;
    },
    setClientOrdersColumn(state, data) {
      state.column = data;
    },
    setClientOrdersOrder(state, data) {
      state.order = data;
    },
    setBreadcrumbs(state, data) {
      state.breadcrumbs = [data];
    },
    addBreadcrumbs(state, data) {
      let i = _.findIndex(state.breadcrumbs, ["index", data.index]);
      if (i > -1) state.breadcrumbs.splice(i, 1);
      state.breadcrumbs.push(data);
    },
    removeBreadcrumbs(state, data) {
      state.breadcrumbs.splice(data, 1);
    },
    toggleShowMenu(state, data) {
      state.showMenu = data;
    }
  },
  getters: {
    homeData(state) {
      return state.data.length;
    },
    breadcrumbsData(state) {
      return state.breadcrumbs;
    },
    showMenu(state) {
      return state.showMenu;
    }
  },
  actions: {
    getHomeData(context) {
      // if (!context.getters.activeUser) return;
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "client/categories",
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setHomeData", res.data);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getCategory(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: `client/categories/${data.id}`,
          method: "get",
          params: {
            page: data.page || undefined,
            show: data.show || undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getProduct(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: `client/products/${data.id}${data.related ? "/related" : ""}`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    searchProducts(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: `client/products`,
          method: "get",
          params: data,
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    addToCart(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        let url = `client/carts/${data.cart_id}`;
        if (data.custom) url = `client/custom-carts`;
        axios({
          url: url,
          method: "patch",
          data: data,
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            if (!data.custom) context.commit("setUserCart", res.data.cart);
            else
              context.commit("updateClientCart", {
                product_id: res.data.product_id,
                quantity: data.product_quantity,
                method: data.method || "add",
                delete: data.delete || undefined
              });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    convertClientCart(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: `client/custom-carts/convert`,
          method: "post",
          data: data,
          headers: context.getters.authorizationHeader
        })
          .then(({ data: { converted_cart } }) => {
            resolve(converted_cart);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    sendOrder(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        let url = context.getters.activeUser.is_client
          ? "client/orders/send"
          : "client/orders";
        axios({
          url: url,
          method: "post",
          data: data,
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setUserCart", res.data.cart);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getClientOrders(context) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: `client/orders`,
          method: "get",
          params: {
            page: context.state.page || undefined,
            column: context.state.column || undefined,
            order: context.state.order || undefined,
            search: context.state.search || undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getClientOrder(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: `client/orders/${data.id}`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    }
  }
};

export default Client;
