<template>
  <transition
    enter-active-class="animate__animated animate__fadeInUp animate__fast"
    leave-active-class="animate__animated animate__fadeOutDown animate__fast"
  >
    <div
      v-if="$route.name == 'Contact'"
      class="contact absolute z-10 self-end flex min-h-screen items-center justify-center"
    >
      <div class="p-20 w-full" @keyup.enter="send">
        <div class="-mx-2 flex flex-wrap">
          <div class="px-2 w-1/2">
            <b-field label="" class="">
              <b-input
                placeholder="Name"
                size="is-medium"
                v-model="name"
                icon="account-question-outline"
              ></b-input>
            </b-field>
            <b-field label="" class="">
              <b-input
                placeholder="Email"
                size="is-medium"
                v-model="email"
                icon="at"
              ></b-input>
            </b-field>
            <b-field label="" class="">
              <b-input
                placeholder="Phone"
                size="is-medium"
                v-model="phone"
                icon="phone-outline"
              ></b-input>
            </b-field>
            <b-field label="" class="">
              <b-input
                placeholder="Company Name"
                size="is-medium"
                v-model="company"
                icon="office-building"
              ></b-input>
            </b-field>
            <b-field label="" class="is-size-5">
              <b-dropdown expanded triggers="['click', 'focus']">
                <b-input
                  icon="factory"
                  v-model="industry"
                  placeholder="Nature of Business"
                  readonly
                  size="is-medium"
                  slot="trigger"
                  slot-scope="{ active }"
                  :icon-right="active ? 'menu-up' : 'menu-down'"
                ></b-input>
                <!-- <div
                  class="height-50 has-background-white flex items-center focus:outline-none"
                  expanded
                  size="is-medium"
                  slot="trigger"
                  slot-scope="{ active }"
                  tabindex="0"
                >
                  <div
                    class="flex w-full items-center justify-between has-text-grey-lighter"
                  >
                    <div class="flex items-center justify-center custom-icon">
                      <b-icon icon="factory" :type="active ? 'is-grey-dark' : ''"></b-icon>
                    </div>
                    <div class="flex-grow">
                      <div v-if="industry" class="has-text-black">
                        {{ industry }}
                      </div>
                      <div v-else class="has-text-grey-light">
                        Nature of Business
                      </div>
                    </div>
                    <div class="flex items-center justify-center custom-icon">
                      <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                    </div>
                  </div>
                </div> -->
                <b-dropdown-item
                  v-for="(business, index) in businesses"
                  :key="index"
                  @click="industry = business"
                >
                  {{ business }}
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
            <b-field>
              <b-autocomplete
                icon="map-marker-radius-outline"
                size="is-medium"
                :data="countries"
                placeholder="Country of Operation"
                field="name"
                @typing="getAsyncData"
                @select="option => (country = option ? option.name : '')"
              >
              </b-autocomplete>
            </b-field>
          </div>
          <div class="px-2 w-1/2">
            <b-field label="" class="">
              <b-input
                type="textarea"
                placeholder="Your message"
                size="is-medium"
                v-model="message"
                max-length="300"
                rows="7"
              ></b-input>
            </b-field>
            <div class="buttons">
              <b-button
                size="is-medium"
                type="is-gold"
                expanded
                :loading="sending"
                @click="send"
                >Send</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    name: "",
    email: "",
    phone: "",
    company: "",
    industry: "",
    country: "",
    message: "",
    businesses: [
      "Themeparks",
      "Shopping Malls",
      "Hotel & Casino",
      "Wholesale & Retail",
      "Others"
    ],
    countries: [],
    sending: false
  }),
  methods: {
    send() {
      this.sending = true;
      axios({
        url: "contact",
        method: "post",
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          company: this.company,
          industry: this.industry,
          country: this.country,
          message: this.message
        }
      })
        .then(res => {
          this.$store.dispatch("displayMessage", {
            message: res.data.message,
            type: "is-gold"
          });
          this.$router.push(
            {
              name: "Index"
            },
            () => {
              this.name = "";
              this.email = "";
              this.phone = "";
              this.company = "";
              this.industry = "";
              this.country = "";
              this.message = "";
            }
          );
        })
        .catch(() => {
          this.$store.dispatch("displayMessage", {
            message: "Please fill in the form below to submit your inquiry.",
            type: "is-warning"
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    getAsyncData: _.debounce(function(name) {
      if (!name) return;
      axios
        .get(`https://restcountries.eu/rest/v2/name/${name}`)
        .then(({ data }) => {
          this.countries = data;
        })
        .finally(() => {
          console.clear();
        });
    }, 1000)
  },
  mounted() {
    if (this.$store.getters.hasUserToken) {
      axios({
        url: "auth/logout",
        method: "post",
        headers: this.$store.getters.authorizationHeader
      });
      this.$store.commit("setUserToken", null);
      this.$store.commit("setUser", null);
    }
  }
};
</script>

<style lang="scss" scoped>
$mainBg: rgba(173, 152, 97, 0.6);
$altBg: rgba(0, 0, 0, 0.6);
$radius: 40vw;
.contact {
  background: $altBg;
  // background: rgba(255, 255, 255, 0.4);
  // backdrop-filter: blur(2px);
  width: 55%;
  min-height: 60vh;
  height: 60vh;
  right: 5vw;
}
.height-50 {
  height: 50px;
}
.custom-icon {
  width: 50px;
  height: 50px;
}
</style>
