const Dashboard = {
  state: () => ({
    stages: null,
    last_cache_activity: null
  }),
  mutations: {
    setStages(state, data) {
      state.stages = data;
    },
    setLastCacheActivity(state, data) {
      state.last_cache_activity = data;
    }
  },
  getters: {},
  actions: {
    getDashboard(context) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "admin/dashboard",
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setStages", res.data.stages);
            context.commit(
              "setLastCacheActivity",
              res.data.last_cache_activity
            );
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    regenerateCache(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: "admin/cache",
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit(
              "setLastCacheActivity",
              res.data.last_cache_activity
            );
            resolve(res);
          })
          .catch(() => {
            reject();
          });
      });
    }
  }
};

export default Dashboard;
