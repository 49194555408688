import router from "../router";
const Permissions = {
  state: () => ({
    data: {},
    page: 1,
    search: "",
    column: "created_at",
    order: "desc"
  }),
  mutations: {
    setPermissionsData(state, data) {
      state.data = data;
    },
    setPermissionsPage(state, data) {
      state.page = data;
    },
    setPermissionsSearch(state, data) {
      state.search = data;
    },
    setPermissionsColumn(state, data) {
      state.column = data;
    },
    setPermissionsOrder(state, data) {
      state.order = data;
    }
  },
  getters: {},
  actions: {
    getPermissions(context) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "admin/permissions",
          method: "get",
          params: {
            page: context.state.page || undefined,
            column: context.state.column || undefined,
            order: context.state.order || undefined,
            search: context.state.search || undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setPermissionsData", res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getPermissionsForm(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `admin/permissions/${data.id ? data.id + "/edit" : "create"}`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    savePermissionsForm(context, data) {
      context.commit("toggleProcessing", true);
      axios({
        url: `admin/permissions${data.id ? "/" + data.id : ""}`,
        method: data.id ? "patch" : "post",
        data: data,
        headers: context.getters.authorizationHeader
      })
        .then(res => {
          router.push({ name: "Permissions" }, () => {
            context.dispatch("displayMessage", {
              type: "is-success",
              message: res.data.message
            });
          });
        })
        .catch(err => {
          context.dispatch("displayMessage", {
            type: "is-danger",
            message: err.response.data.message
          });
          context.commit("setErrorsData", err.response);
          context.commit("toggleProcessing", false);
        });
    }
  }
};

export default Permissions;
