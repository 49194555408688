import { _ } from "core-js";
import router from "../router";
const Products = {
  state: () => ({
    data: {},
    page: 1,
    search: "",
    column: "created_at",
    order: "desc",
    perPage: 25,
    colors: {},
    selectedCategory: "*",
    openedRows: [],
    visibleColumns: {
      cat: {
        name: "cat",
        label: "Category",
        visible: false
      },
      length: {
        name: "length",
        label: "Length",
        visible: true
      },
      width: {
        name: "width",
        label: "Width",
        visible: true
      },
      height: {
        name: "height",
        label: "Height",
        visible: true
      },
      volume_packed: {
        name: "volume_packed",
        label: "Packed Volume",
        visible: false
      },
      parts: {
        name: "parts",
        label: "No. of Parts",
        visible: false
      },
      weight_net: {
        name: "weight_net",
        label: "Net Weight",
        visible: false
      },
      weight_gross: {
        name: "weight_gross",
        label: "Gross Weight",
        visible: false
      },
      is_illuminated: {
        name: "is_illuminated",
        label: "Light",
        visible: false
      },
      updated_at: {
        name: "updated_at",
        label: "Updated",
        visible: false
      }
    }
  }),
  mutations: {
    setProductsData(state, data) {
      state.data = data;
    },
    setProductsPage(state, data) {
      state.page = data;
    },
    setProductsSearch(state, data) {
      state.search = data;
    },
    setProductsColumn(state, data) {
      state.column = data;
    },
    setProductsOrder(state, data) {
      state.order = data;
    },
    setProductsPerPage(state, data) {
      state.perPage = data;
    },
    setProductsColors(state, data) {
      state.colors = data;
    },
    setProductsSelectedCategory(state, data) {
      state.selectedCategory = data;
    },
    setProductsOpenedRows(state, data) {
      state.openedRows = _.map(data, p => {
        if (p.variants.length) return p.id;
      });
    }
  },
  getters: {
    filterRalColors: state => data => {
      return state.colors.rals.filter(color => {
        return (
          color.code
            .toString()
            .toLowerCase()
            .indexOf(data.toLowerCase()) > -1 ||
          color.name
            .toString()
            .toLowerCase()
            .indexOf(data.toLowerCase()) > -1
        );
      });
    },
    filterPantoneColors: state => data => {
      return state.colors.pantones.filter(color => {
        return (
          color.code
            .toString()
            .toLowerCase()
            .indexOf(data.toLowerCase()) > -1 ||
          color.name
            .toString()
            .toLowerCase()
            .indexOf(data.toLowerCase()) > -1
        );
      });
    },
    productsOpenedRows: state => {
      return _.map(state.data.data, p => {
        if (p.variants.length) return p.id;
      });
    },
    productsVisibleColumns: state => {
      return state.visibleColumns;
    }
  },
  actions: {
    getProducts(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "admin/products",
          method: "get",
          params: {
            page: context.state.page || undefined,
            column: context.state.column || undefined,
            order: context.state.order || undefined,
            per_page: context.state.perPage || undefined,
            search: context.state.search || undefined,
            bracket: _.has(data, "bracket") ? data.bracket : undefined,
            category: context.state.selectedCategory || undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setProductsData", res.data);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getProductsForm(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `admin/products/${data.id ? data.id + "/edit" : "create"}${
            data.fr ? `?id=${data.fr}` : ""
          }`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveProductsForm(context, data) {
      context.commit("toggleProcessing", true);
      axios({
        url: `admin/products${data.id ? "/" + data.id : ""}`,
        method: data.id ? "patch" : "post",
        data: data,
        headers: context.getters.authorizationHeader
      })
        .then(res => {
          router.push({ name: "Products" }, () => {
            context.dispatch("displayMessage", {
              type: "is-success",
              message: res.data.message
            });
          });
        })
        .catch(err => {
          context.dispatch("displayMessage", {
            type: "is-danger",
            message: err.response.data.message
          });
          context.commit("setErrorsData", err.response);
          context.commit("toggleProcessing", false);
        });
    },
    getProductColors(context) {
      axios({
        url: `admin/colors`,
        method: "get",
        headers: context.getters.authorizationHeader
      })
        .then(res => {
          context.commit("setProductsColors", res.data);
        })
        .catch(err => {
          throw err;
        });
    }
  }
};

export default Products;
