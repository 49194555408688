import router from "../router";
import { DialogProgrammatic as Dialog } from "buefy";

const Auth = {
  state: () => ({
    userToken: localStorage.getItem("userToken") || null,
    user: null,
    cart: null,
    clientCart: JSON.parse(localStorage.getItem("cart")) || null
  }),
  getters: {
    authorizationHeader: state => {
      return state.userToken
        ? { Authorization: `Bearer ${state.userToken}` }
        : null;
    },
    multiPartAuthorizationHeader: state => {
      return state.userToken
        ? {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${state.userToken}`
          }
        : null;
    },
    hasUserToken: state => {
      return state.userToken ? true : false;
    },
    activeUser: state => {
      return state.user;
    },
    userRole: state => {
      return state.user ? state.user.role : false;
    },
    userIsClient: state => {
      return state.user ? state.user.is_client : false;
    },
    userPermissions: state => {
      return state.user ? state.user.permissions : null;
    },
    userHasPermission: state => data => {
      if (state.user.role == "super-admin") {
        return true;
      } else {
        if (_.isArray(data)) {
          return state.user
            ? _.intersection(state.user.permissions, data).length == data.length
              ? true
              : false
            : false;
        } else {
          return state.user ? _.includes(state.user.permissions, data) : false;
        }
      }
    },
    userCart: state => {
      return state.cart;
    },
    clientCart: state => {
      return state.clientCart;
    }
  },
  mutations: {
    setUserToken(state, data) {
      if (_.isNull(data)) {
        localStorage.removeItem("userToken");
      } else {
        localStorage.setItem("userToken", data);
      }
      state.userToken = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setUserCart(state, data) {
      state.cart = data;
    },
    setClientCart(state, { user_id, client_name, client_email, products }) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (!_.isObject(cart)) localStorage.removeItem("cart");
      if (!cart) {
        // new cart
        cart = {
          user_id: user_id,
          client_name: client_name,
          client_email: client_email,
          products: products
        };
        // localStorage.setItem("cart", JSON.stringify(cart));
        // return;
      } else {
        if (cart.user_id == user_id && cart.client_email == client_email) {
          cart.client_name = client_name;
        } else {
          cart.user_id = user_id;
          cart.client_name = client_name;
          cart.client_email = client_email;
          cart.products = [];
        }
      }
      localStorage.setItem("cart", JSON.stringify(cart));
    },
    updateClientCart(state, data) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      let initProduct = { id: data.product_id, quantity: data.quantity };
      if (data.method == "remove") {
        // TODO: remove product and update store to animate
        cart.products.splice(
          _.findIndex(cart.products, ["id", data.product_id])
        );
      } else {
        let product = _.find(cart.products, ["id", data.product_id]);
        // console.log(data.method)
        if (product) {
          if (data.method == "assign") product.quantity = data.quantity;
          else product.quantity++;
        } else cart.products.push(initProduct);
      }
      localStorage.setItem("cart", JSON.stringify(cart));
      state.clientCart = cart;
    },
    emptyClientCart(state) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      cart.products = [];
      state.clientCart = cart;
      localStorage.setItem("cart", JSON.stringify(cart));
    },
    setClientCartState(state, data) {
      state.clientCart = data;
      if (_.isNull(data)) localStorage.removeItem("cart");
    }
  },
  actions: {
    login(context, data) {
      context.commit("toggleLoading", true);
      axios({
        url: process.env.VUE_APP_API_CSRF,
        method: "get"
      })
        .then(() => {
          axios({
            // url: `${process.env.VUE_APP_API}/web/auth/login`,
            url: "auth/login",
            method: "post",
            data: data
          })
            .then(res => {
              if (!res.data.user.is_client)
                context.dispatch("displayMessage", {
                  type: "is-success",
                  message: res.data.message
                });
              context.commit("setUserToken", res.data.token);
              context.commit("setUser", res.data.user);
              context.commit("setUserCart", res.data.user.cart);
              // if (_.includes(res.data.user.permissions, "read-dashboard")) {
              //   router.push({ name: "Dashboard" });
              // } else {
              //   router.push({ name: "Home" });
              // }
              if (!res.data.user.is_client) router.push({ name: "Home" });
              else router.push({ name: "Continue As" });
            })
            .catch(err => {
              context.dispatch("displayMessage", {
                type: "is-warning",
                message: err.response.data.message
              });
              context.commit("toggleLoading", false);
            });
        })
        .catch(() => {
          context.commit("toggleLoading", false);
          context.dispatch("displayMessage", {
            type: "is-warning",
            message: context.getters.generalErrorMessage
          });
        });
    },
    continueAs(context, data) {
      context.commit("toggleLoading", true);
      axios({
        url: "auth/continue-as",
        method: "post",
        data: data,
        headers: context.getters.authorizationHeader
      })
        .then(res => {
          context.dispatch("displayMessage", {
            type: "is-success",
            message: res.data.message
          });
          context.commit("setClientCart", res.data);
          context.commit(
            "setClientCartState",
            JSON.parse(localStorage.getItem("cart"))
          );
          router.push({ name: "Home" });
        })
        .catch(err => {
          context.dispatch("displayMessage", {
            type: "is-warning",
            message:
              err.response.data.message || context.getters.generalErrorMessage
          });
          context.commit("setErrorsData", err.response);
        })
        .finally(() => {
          context.commit("toggleLoading", false);
        });
    },
    logout(context) {
      Dialog.confirm({
        type: "is-gold",
        message: "Do you want to logout?",
        onConfirm: () => {
          axios({
            url: "auth/logout",
            method: "post",
            headers: context.getters.authorizationHeader
          });
          context.commit("setUserToken", null);
          context.commit("setUser", null);
          context.commit("setClientCartState", null);
          router.push({ name: "Login" });
        }
      });
    },
    getUser(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: "auth/user",
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setUser", res.data);
            context.commit("setUserCart", res.data.cart);
            resolve();
          })
          .catch(() => {
            context.commit("setUserToken", null);
            context.commit("setUser", null);
            reject();
          });
      });
    },
    getAccount(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: "auth/user",
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveAccountForm(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleProcessing", true);
        axios({
          url: `auth/user/${data.id}`,
          method: "patch",
          data: data,
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.dispatch("displayMessage", {
              type: "is-success",
              message: res.data.message
            });
            resolve(res);
            context.commit("setErrorsData", null);
          })
          .catch(err => {
            context.dispatch("displayMessage", {
              type: "is-danger",
              message: err.response.data.message
            });
            context.commit("setErrorsData", err.response);
            reject(err);
          })
          .finally(() => {
            context.commit("toggleProcessing", false);
          });
      });
    }
  }
};

export default Auth;
