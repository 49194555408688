<template>
  <transition>
    <div
      class="menu-content flex items-center justify-center"
      @click="hideMenu"
      v-if="showMenu"
    >
      <div class="w-1/2">
        <!-- <div class="is-size-4 has-text-white has-text-centered mb-4">Menu</div> -->
        <div class="px-2">
          <div class="-mx-4 flex flex-wrap">
            <div
              class="w-1/4 mb-4 px-2"
              v-for="(item, index) in data"
              :key="index"
            >
              <router-link
                :to="{ name: 'Category', params: { id: item.slug } }"
                class="block border border-transparent category"
              >
                <div class="has-text-centered my-4 is-size-5 has-text-white">
                  {{ item.name }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { Events } from "@/events.js";
export default {
  computed: {
    data() {
      return this.$store.state.client.data;
    },
    showMenu() {
      return this.$store.getters.showMenu;
    }
  },
  watch: {
    showMenu() {
      let html = document.documentElement;
      if (this.showMenu) {
        html.classList.add("is-clipped");
      } else {
        html.classList.remove("is-clipped");
      }
    }
  },
  methods: {
    hideMenu() {
      this.$store.commit("toggleShowMenu", false);
    }
  },
  mounted() {
    if (this.$store.getters.hasUserToken && !this.$store.getters.homeData) {
      this.$store.dispatch("getHomeData");
    }
  }
};
</script>

<style lang="scss" scoped>
$mainBg: rgba(173, 152, 97, 0.6);
$altBg: rgba(0, 0, 0, 0.6);
$lightBg: rgba(255, 255, 255, 0.6);
.menu-content {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(10px);
  background: $mainBg;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
}

.category {
  background: darken($mainBg, 2%);
  // backdrop-filter: blur(5px);
  // background: darken($mainBg, 0.5%);
}
</style>
