const Files = {
  state: () => ({
    data: {},
    increments: 20,
    skip: 0,
    take: 20,
    search: "",
    selectedType: "*"
  }),
  mutations: {
    setFilesData(state, data) {
      state.data = data;
    },
    incrementFilesSkip(state, data) {
      state.skip += data;
    },
    incrementFilesTake(state) {
      state.take += state.increments;
    },
    setFilesSearch(state, data) {
      state.search = data;
    },
    setFilesSelectedType(state, data) {
      state.selectedType = data;
    }
  },
  getters: {},
  actions: {
    getFiles(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "admin/files",
          method: "get",
          params: {
            skip: context.state.skip || undefined,
            take: context.state.take || undefined,
            search: context.state.search || undefined,
            type: context.state.selectedType || undefined,
            ids: data ? (data.ids ? data.ids : undefined) : undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setFilesData", res.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getFile(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `admin/files/${data.id}`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getFilesForm(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `admin/files/${data.id ? data.id + "/edit" : "create"}`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveFilesForm(context, data) {
      context.commit("toggleProcessing", true);
      let formData = new FormData();
      let i = 0;
      _.forEach(data, file => {
        formData.append(`files[${i}]`, file);
        i++;
      });
      axios({
        url: `admin/files`,
        method: "post",
        data: formData,
        headers: context.getters.multiPartAuthorizationHeader
      })
        .then(res => {
          context.dispatch("displayMessage", {
            type: "is-success",
            message: res.data.message
          });
          context.dispatch("getFiles");
        })
        .catch(err => {
          context.dispatch("displayMessage", {
            type: "is-danger",
            message: err.response.data.message
          });
          context.commit("setErrorsData", err.response);
        })
        .finally(() => {
          context.commit("toggleProcessing", false);
        });
    }
  }
};

export default Files;
