<template>
  <b-modal
    v-model="visible"
    has-modal-card
    trap-focus
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-modal
    full-screen
    :on-cancel="onCancel"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="">
          Files
        </div>
        <b-field class="file is-success mb-0 ml-4">
          <b-upload v-model="files" multiple @input="onUpload">
            <span class="file-cta">
              <span class="file-label">Upload</span>
            </span>
          </b-upload>
        </b-field>
        <div class="flex-grow"></div>
        <div @keyup.enter="getFiles">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </header>
      <section class="modal-card-body">
        <div class="px-2 is-relative">
          <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
          <div
            class="-mx-4 flex flex-wrap files-container"
            ref="files-container"
            @scroll="onScroll"
          >
            <div
              class="w-1/5 px-2 mb-4"
              v-for="(file, index) in data.data"
              :key="index"
            >
              <div
                class="file-item"
                :class="{ 'file-item-selected': itemSelected(file.id) }"
                @click="onBulkSelect(file.id)"
              >
                <b-image
                  :src="
                    file.type == 'document'
                      ? $default_document_thumb
                      : file.thumb
                  "
                  :alt="file.filename"
                  ratio="1by1"
                  lazy
                ></b-image>
                <p class="bg-gray-200 p-2 break-all">{{ file.filename }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="flex-grow"></div>
        <b-button @click="onCancel">Cancel</b-button>
        <b-button type="is-success" @click="onSave">Save changes</b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { Events } from "@/events.js";
import store from "@/store";
export default {
  data: () => ({
    visible: false,
    multiple: false,
    checkable: true,
    checkedRows: [],
    files: [],
    image_id: null,
    image_ids: null,
    emit: ""
  }),
  computed: {
    data() {
      return store.state.files.data;
    },
    isLoading() {
      return store.getters.isLoading;
    },
    search: {
      get() {
        return store.state.files.search;
      },
      set(newValue) {
        store.commit("setFilesSearch", newValue);
      }
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.getFiles();
    }, store.getters.delay)
  },
  methods: {
    show(params) {
      this.visible = true;
      this.multiple = params.multiple;
      this.image_id = params.image_id || null;
      this.image_ids = params.image_ids || null;
      this.emit = params.emit;
      if (!this.multiple) {
        this.checkedRows = this.image_id ? [this.image_id] : [];
      } else {
        this.checkedRows = JSON.parse(JSON.stringify(params.image_ids));
      }
      store.commit("setFilesSelectedType", params.type);
      this.getFiles();
    },
    onCancel() {
      this.image_id = null;
      this.image_ids = null;
      store.commit("setFilesSelectedType", "*");
      store.commit("setFilesSearch", "");
      this.visible = false;
      if (!this.multiple) {
        this.checkedRows = this.image_id ? [this.image_id] : [];
      }
    },
    onSave() {
      store.commit("setFilesSelectedType", "*");
      this.visible = false;
      Events.$emit(this.emit, this.checkedRows);
    },
    getFiles() {
      store.dispatch("getFiles", { ids: this.checkedRows });
    },
    onUpload() {
      store.dispatch("saveFilesForm", this.files).finally(() => {
        this.files = [];
      });
    },
    onScroll() {
      let currentScroll =
        this.$refs["files-container"].scrollHeight -
        this.$refs["files-container"].scrollTop;
      let endScroll = this.$refs["files-container"].offsetHeight;
      if (_.floor(currentScroll) === endScroll) {
        store.commit("incrementFilesTake");
        this.getFiles();
      }
    },
    onBulkSelect(id) {
      if (this.checkable) {
        let i = _.indexOf(this.checkedRows, id);
        if (this.multiple) {
          if (i > -1) {
            this.checkedRows.splice(i, 1);
          } else {
            this.checkedRows.push(id);
          }
        } else {
          if (i > -1) {
            this.checkedRows = [];
          } else {
            this.checkedRows = [id];
          }
        }
      }
    },
    itemSelected(id) {
      if (this.checkable) {
        let i = _.indexOf(this.checkedRows, id);
        if (i > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  beforeMount() {
    Events.$on("show", params => {
      this.show(params);
    });
  }
};
</script>

<style lang="scss" scoped>
.files-container {
  height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  background: white;
}
.file-item {
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.file-item-selected {
  border: 2px solid hsl(141, 71%, 48%) !important;
}
</style>
