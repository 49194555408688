// import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "./assets/scss/app.scss";
import VueProgressBar from "vue-progressbar";
import FilesBrowser from "@/plugins/files-browser";
import PageTitle from "@/_mixins/pageTitleMixin";

Vue.mixin(PageTitle);

Vue.use(Buefy);
Vue.use(VueProgressBar, {
  // color: "#23d160",
  color: "#ad9861",
  failedColor: "#874b4b",
  thickness: "4px",
  transition: {
    speed: "0.5s",
    opacity: "0.6s",
    termination: 500
  },
  autoRevert: true,
  location: "top",
  inverse: false
});
Vue.use(FilesBrowser);
Vue.use(VueApexCharts);

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

Vue.prototype.$logo = require("@/assets/logo.svg");
Vue.prototype.$default_image_thumb = require("@/assets/no-image.jpg");
Vue.prototype.$blank_image_thumb = require("@/assets/no-image.jpg");
Vue.prototype.$default_document_thumb = require("@/assets/pdf.jpg");
Vue.prototype.$default_add_thumb = require("@/assets/add-image.jpg");

Vue.directive("click-outside", {
  bind: function(el, binding) {
    const outsideClick = event => {
      event.stopPropagation();
      if (!el.contains(event.target)) {
        binding.value(event);
      }
    };
    // attach function to element to remove later
    el.outsideClick = outsideClick;

    // add the function to listeners
    window.addEventListener("click", outsideClick);
  },
  unbind: function(el) {
    // remove listener
    window.removeEventListener("click", el.outsideClick);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
