// import Vue from "vue";
// import VueRouter from "vue-router";
import store from "../store";
import Index from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
    // redirect: {
    //   name: "Login"
    // }
  },
  {
    path: "/login",
    name: "Login",
    // component: () => import("../views/Login.vue")
    component: Index
  },
  {
    path: "/about",
    name: "About",
    // component: () => import("../views/Login.vue")
    component: Index
  },
  {
    path: "/contact",
    name: "Contact",
    // component: () => import("../views/Login.vue")
    component: Index
  },
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: () => import("../views/admin/Dashboard.vue"),
    meta: {
      userCan: ["read-dashboard"],
      dispatch: "getDashboard"
    }
  },
  {
    path: "/admin/orders",
    name: "Orders",
    component: () => import("../views/admin/orders/Orders.vue"),
    meta: {
      userCan: ["read-dashboard", "read-orders"],
      dispatch: "getOrders"
    }
  },
  {
    path: "/admin/orders/create",
    name: "Create Order",
    component: () => import("../views/admin/orders/OrdersForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-orders"],
      dispatch: "getOrdersForm"
    }
  },
  {
    path: "/admin/orders/:id/edit",
    name: "Edit Order",
    component: () => import("../views/admin/orders/OrdersForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-orders"],
      dispatch: "getOrdersForm"
    }
  },
  {
    path: "/admin/products",
    name: "Products",
    component: () => import("../views/admin/products/Products.vue"),
    meta: {
      userCan: ["read-dashboard", "read-products"],
      dispatch: "getProducts"
    }
  },
  {
    path: "/admin/products/create",
    name: "Create Product",
    component: () => import("../views/admin/products/ProductsForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-products"],
      dispatch: "getProductsForm"
    }
  },
  {
    path: "/admin/products/:id/edit",
    name: "Edit Product",
    component: () => import("../views/admin/products/ProductsForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-products"],
      dispatch: "getProductsForm"
    }
  },
  {
    path: "/admin/categories",
    name: "Categories",
    component: () => import("../views/admin/categories/Categories.vue"),
    meta: {
      userCan: ["read-dashboard", "read-categories"],
      dispatch: "getCategories"
    }
  },
  {
    path: "/admin/categories/create",
    name: "Create Category",
    component: () => import("../views/admin/categories/CategoriesForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-categories"],
      dispatch: "getCategoriesForm"
    }
  },
  {
    path: "/admin/categories/:id/edit",
    name: "Edit Category",
    component: () => import("../views/admin/categories/CategoriesForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-categories"],
      dispatch: "getCategoriesForm"
    }
  },
  {
    path: "/admin/joborders",
    name: "Joborders",
    component: () => import("../views/admin/joborders/Joborders.vue"),
    meta: {
      userCan: ["read-dashboard", "read-joborders"],
      dispatch: "getJoborders"
    }
  },
  {
    path: "/admin/joborders/create",
    name: "Create Joborder",
    component: () => import("../views/admin/joborders/JobordersForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-joborders"],
      dispatch: "getJobordersForm"
    }
  },
  {
    path: "/admin/joborders/:id/edit",
    name: "Edit Joborder",
    component: () => import("../views/admin/joborders/JobordersForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-joborders"],
      dispatch: "getJobordersForm"
    }
  },
  {
    path: "/admin/items",
    name: "Items",
    component: () => import("../views/admin/items/Items.vue"),
    meta: {
      userCan: ["read-dashboard", "read-items"],
      dispatch: "getItems"
    }
  },
  {
    path: "/admin/items/:id/edit",
    name: "Edit Item",
    component: () => import("../views/admin/items/ItemsForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-items"],
      dispatch: "getItemsForm"
    }
  },
  {
    path: "/admin/costing",
    name: "Costing",
    component: () => import("../views/admin/Dashboard.vue"),
    meta: {
      userCan: ["read-dashboard", "read-costs"]
    }
  },
  {
    path: "/admin/materials",
    name: "Materials",
    component: () => import("../views/admin/Dashboard.vue"),
    meta: {
      userCan: ["read-dashboard", "read-materials"]
    }
  },
  {
    path: "/admin/references",
    name: "References",
    component: () => import("../views/admin/references/References.vue"),
    meta: {
      userCan: ["read-dashboard", "read-references"],
      dispatch: "getReferences"
    }
  },
  {
    path: "/admin/files",
    name: "Files",
    component: () => import("../views/admin/files/Files.vue"),
    meta: {
      userCan: ["read-dashboard", "read-files"],
      dispatch: "getFiles"
    }
  },
  {
    path: "/admin/users",
    name: "Users",
    component: () => import("../views/admin/users/Users.vue"),
    meta: {
      userCan: ["read-dashboard", "read-users"],
      dispatch: "getUsers"
    }
  },
  {
    path: "/admin/users/create",
    name: "Create User",
    component: () => import("../views/admin/users/UsersForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-users"],
      dispatch: "getUsersForm"
    }
  },
  {
    path: "/admin/users/:id/edit",
    name: "Edit User",
    component: () => import("../views/admin/users/UsersForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-users"],
      dispatch: "getUsersForm"
    }
  },
  {
    path: "/admin/roles",
    name: "Roles",
    component: () => import("../views/admin/roles/Roles.vue"),
    meta: {
      userCan: ["read-dashboard", "read-roles"],
      dispatch: "getRoles"
    }
  },
  {
    path: "/admin/roles/create",
    name: "Create Role",
    component: () => import("../views/admin/roles/RolesForm.vue"),
    meta: {
      userCan: ["read-dashboard", "read-roles"],
      dispatch: "getRolesForm"
    }
  },
  {
    path: "/admin/roles/:id/edit",
    name: "Edit Role",
    component: () => import("../views/admin/roles/RolesForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-roles"],
      dispatch: "getRolesForm"
    }
  },
  {
    path: "/admin/permissions",
    name: "Permissions",
    component: () => import("../views/admin/permissions/Permissions.vue"),
    meta: {
      userCan: ["read-dashboard", "read-permissions"],
      dispatch: "getPermissions"
    }
  },
  {
    path: "/admin/permissions/create",
    name: "Create Permission",
    component: () => import("../views/admin/permissions/PermissionsForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-permissions"],
      dispatch: "getPermissionsForm"
    }
  },
  {
    path: "/admin/permissions/:id/edit",
    name: "Edit Permission",
    component: () => import("../views/admin/permissions/PermissionsForm.vue"),
    meta: {
      userCan: ["read-dashboard", "write-permissions"],
      dispatch: "getPermissionsForm"
    }
  },
  {
    path: "/admin/account",
    name: "Account",
    component: () => import("../views/admin/account/AccountForm.vue"),
    meta: {
      userCan: ["read-dashboard", "read-account"],
      dispatch: "getAccount"
    }
  },
  {
    path: "/client/continue-as",
    name: "Continue As",
    component: () => import("../views/client/ContinueAs.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/client/home",
    name: "Home",
    component: () => import("../views/client/Home.vue"),
    meta: {
      requiresAuth: true,
      dispatch: "getHomeData"
    }
  },
  {
    path: "/client/home/products",
    name: "ProductList",
    component: () => import("../views/client/ProductList.vue"),
    meta: {
      requiresAuth: true,
      dispatch: "searchProducts"
    }
    // redirect: { name: "Home" }
  },
  {
    path: "/client/home/products/:id/",
    name: "Product",
    component: () => import("../views/client/Product.vue"),
    meta: {
      requiresAuth: true,
      dispatch: "getProduct"
    }
  },
  {
    path: "/client/home/categories",
    redirect: { name: "Home" }
  },
  {
    path: "/client/home/:id",
    name: "Category",
    component: () => import("../views/client/Category.vue"),
    meta: {
      requiresAuth: true,
      dispatch: "getCategory"
    }
  },
  {
    path: "/client/account",
    name: "My Account",
    component: () => import("../views/client/MyAccount.vue"),
    meta: {
      requiresAuth: true,
      userCan: ["write-account"],
      dispatch: "getAccount"
    }
  },
  {
    path: "/client/cart",
    name: "My Cart",
    component: () => import("../views/client/MyCart.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/client/orders",
    name: "My Orders",
    component: () => import("../views/client/MyOrders.vue"),
    meta: {
      requiresAuth: true,
      userCan: ["write-carts"],
      dispatch: "getClientOrders"
    }
  },
  {
    path: "/client/orders/:id",
    name: "My Order",
    component: () => import("../views/client/MyOrder.vue"),
    meta: {
      requiresAuth: true,
      userCan: ["write-carts"],
      dispatch: "getClientOrder"
    }
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("../views/client/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

const checkAndRoute = (name, to, from, next) => {
  // if(from.name == 'Continue As') {
  //   console.log(store.getters.activeUser.is_client)
  //   if(store.getters.activeUser.is_client && _.isNull(JSON.parse(localStorage.getItem('cart'))))
  //   next({name: 'Continue As'})
  // }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.matched.some(record => record.meta.userCan)) {
      if (!store.getters.userHasPermission(to.meta.userCan))
        next({ name: name });
    }
  } else {
    if (
      (to.name == "Login" || to.name == "Index" || to.name == "Contact") &&
      store.getters.activeUser
    )
      next({ name: "Home" });
  }
  next();
};

router.beforeEach((to, from, next) => {
  store.commit("toggleRouting", true);
  if (store.getters.hasUserToken) {
    if (!store.getters.activeUser) {
      store
        .dispatch("getUser")
        .then(() => {
          // if (to.matched.some(record => record.meta.requiresAuth)) {
          //   if (to.matched.some(record => record.meta.userCan)) {
          //     if (!store.getters.userHasPermission(to.meta.userCan))
          //       next({ name: "Login" });
          //   }
          // } else {
          //   if (
          //     (to.name == "Login" ||
          //       to.name == "Index" ||
          //       to.name == "Contact") &&
          //     store.getters.activeUser
          //   )
          //     next({ name: "Home" });
          // }
          checkAndRoute("Login", to, from, next);
        })
        .catch(() => {
          next({ name: "Login" });
        });
    } else {
      // if (to.matched.some(record => record.meta.requiresAuth)) {
      //   if (to.matched.some(record => record.meta.userCan)) {
      //     if (!store.getters.userHasPermission(to.meta.userCan))
      //       next({ name: "Home" });
      //   }
      // } else {
      //   if (
      //     to.name == "Login" ||
      //     to.name == "Index" ||
      //     (to.name == "Contact" && store.getters.activeUser)
      //   )
      //     next({ name: "Home" });
      // }
      checkAndRoute("Home", to, from, next);
    }
  } else {
    if (
      to.matched.some(record => record.meta.userCan) ||
      to.matched.some(record => record.meta.requiresAuth)
    )
      next({ name: "Login" });
    next();
  }
});

router.afterEach(() => {
  store.commit("toggleLoading", false);
  store.commit("toggleRouting", false);
  store.commit("toggleProcessing", false);
  store.commit("setErrorsData", null);
});

export default router;
