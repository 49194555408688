import router from "../router";
import { ToastProgrammatic as Toast } from "buefy";
import { DialogProgrammatic as Dialog } from "buefy";

const Global = {
  state: () => ({
    routing: false,
    loading: false,
    processing: false,
    duration: 3000,
    delay: 500
  }),
  getters: {
    isRouting(state) {
      return state.routing;
    },
    isLoading(state) {
      return state.loading;
    },
    isProcessing(state) {
      return state.processing;
    },
    delay(state) {
      return state.delay;
    },
    duration(state) {
      return state.duration;
    }
  },
  mutations: {
    toggleLoading(state, data) {
      state.loading = data;
    },
    toggleRouting(state, data) {
      state.routing = data;
    },
    toggleProcessing(state, data) {
      state.processing = data;
    }
  },
  actions: {
    displayMessage(context, data) {
      Toast.open({
        message: data.message,
        type: data.type,
        duration: context.getters.duration
      });
    },
    deleteSelection(context, data) {
      return new Promise((resolve, reject) => {
        Dialog.confirm({
          message: data.message,
          onConfirm: () => {
            context.commit("toggleProcessing", true);
            context.commit("toggleLoading", true);
            axios({
              url: `admin/${data.path}`,
              data: data.ids,
              method: "delete",
              headers: context.getters.authorizationHeader
            })
              .then(res => {
                if (_.has(data, "dispatch")) {
                  context.dispatch(data.dispatch).then(() => {
                    context.dispatch("displayMessage", {
                      type: "is-success",
                      message: res.data.message
                    });
                    context.commit("toggleLoading", false);
                    context.commit("toggleProcessing", false);
                  });
                } else {
                  router.push({ name: data.push }, () => {
                    context.dispatch("displayMessage", {
                      type: "is-success",
                      message: res.data.message
                    });
                  });
                }
                resolve();
              })
              .catch(err => {
                context.dispatch("displayMessage", {
                  type: "is-danger",
                  message: context.getters.generalErrorMessage
                });
                context.commit("setErrorsData", err.response);
                context.commit("toggleProcessing", false);
                context.commit("toggleLoading", false);
                reject();
              });
          }
        });
      });
    }
  }
};

export default Global;
