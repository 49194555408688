import router from "../router";
const Items = {
  state: () => ({
    data: {},
    page: 1,
    search: "",
    column: "created_at",
    order: "desc"
  }),
  mutations: {
    setItemsData(state, data) {
      state.data = data;
    },
    setItemsPage(state, data) {
      state.page = data;
    },
    setItemsSearch(state, data) {
      state.search = data;
    },
    setItemsColumn(state, data) {
      state.column = data;
    },
    setItemsOrder(state, data) {
      state.order = data;
    }
  },
  getters: {},
  actions: {
    getItems(context) {
      return new Promise((resolve, reject) => {
        context.commit("toggleLoading", true);
        axios({
          url: "admin/items",
          method: "get",
          params: {
            page: context.state.page || undefined,
            column: context.state.column || undefined,
            order: context.state.order || undefined,
            search: context.state.search || undefined
          },
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            context.commit("setItemsData", res.data);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            context.commit("toggleLoading", false);
          });
      });
    },
    getItemsForm(context, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `admin/items/${data.id ? data.id + "/edit" : `create`}${
            data.fr ? `?id=${data.fr}` : ""
          }`,
          method: "get",
          headers: context.getters.authorizationHeader
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveItemsForm(context, data) {
      context.commit("toggleProcessing", true);
      axios({
        url: `admin/items${data.id ? "/" + data.id : ""}`,
        method: data.id ? "patch" : "post",
        data: data,
        headers: context.getters.authorizationHeader
      })
        .then(res => {
          router.push({ name: "Items" }, () => {
            context.dispatch("displayMessage", {
              type: "is-success",
              message: res.data.message
            });
          });
        })
        .catch(err => {
          context.dispatch("displayMessage", {
            type: "is-danger",
            message: err.response.data.message
          });
          context.commit("setErrorsData", err.response);
          context.commit("toggleProcessing", false);
        });
    }
  }
};

export default Items;
